/* eslint prefer-promise-reject-errors: ["off"] */
import uuid4 from 'uuid/v4';

import { UNKNOWN_HTTP_ERROR_MESSAGE } from '../defaults';

import { simpleAjaxDispatch } from './http';
import { watchCorrelationId } from './longpoller';

export function submitScan(type, json) {
  const allData = { ...json, correlationId: uuid4() };

  const typeParts = type.split('_GENACC');

  let finalValue = null;
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const watchProm = watchCorrelationId(allData.correlationId, (err, value) => {
      if (err) return;
      // routingKey is like event.rpc.result.<ev>.<qualName>
      const event = value.routingKey.split('.')[3];
      if (event === 'complete') {
        if (finalValue) {
          if (finalValue.event === 'value') {
            resolve(JSON.parse(finalValue.content));
          } else if (finalValue.event === 'error') {
            reject(JSON.parse(finalValue.content));
          } else {
            reject({ _errors: [UNKNOWN_HTTP_ERROR_MESSAGE] });
          }
        } else {
          reject({ _errors: [UNKNOWN_HTTP_ERROR_MESSAGE] });
        }
      } else {
        finalValue = value;
        finalValue.event = event;
      }
    })
      .then(() => {
        simpleAjaxDispatch(`/scan/${typeParts[0]}`, 'submitScan', {
          json: allData, method: 'POST',
        })(dispatch, getState)
          .then(action => {
            if (action.failed) {
              reject(action.data);
            }
          })
          .catch(err => reject({ _errors: [err] }))
          .finally(() => watchProm.cancel());
      })
      .catch(() => { });
  });
}
