import _ from 'lodash';
import {
  Alert, Button, Col, Form, Input, Row, Spin, Modal, message, Select, Collapse, Icon, Switch,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BreadcrumbLevel } from '../BreadcrumbLevel';
import * as actions from '../../actions';
import { DoCEmail, determineCredsStatus } from '../fields/DoCEmail';
import { checkFeature } from '../../utils';
import { withContentLayout } from '../layout/Layout';

const _getTag = (tags, predicate) => (tags ? tags.find(predicate) : null);
const NOTIFICATION_CATS_BASE = [
  {
    fieldSuffix: 'user',
    trueValue: 'organization',
    getTag: tags => _getTag(tags, tag => tag.get('role') === 'smart.user'),
  },
  {
    fieldSuffix: 'constituent',
    trueValue: 'own',
    getTag: tags => _getTag(tags, tag => tag.getIn(['parent', 'role']) === 'organization'),
  },
];

const OCGCredentialsForm = ({
  closeOCGCredentialsModal,
  submitOCGCredentials,
  instituteType,
  institutionsCredentials,
  OCGCredsSaveClicked,
}) => {
  const usernameInput = React.createRef();
  const passwordInput = React.createRef();
  const { username, password } = (institutionsCredentials && institutionsCredentials[instituteType]) || {};

  const submitData = e => {
    e.preventDefault();
    const usernameVal = usernameInput.current && usernameInput.current.state;
    const passwordVal = passwordInput.current && passwordInput.current.state;
    submitOCGCredentials({ username: usernameVal && usernameVal.value, password: passwordVal && passwordVal.value, state: instituteType });
  };
  return (<Modal
    visible={true}
    title={'OCG Credentials'}
    width={800}
    onCancel={closeOCGCredentialsModal}
    footer={null}
          >
    <Form onSubmit={submitData}>
      <Form.Item label="Username" name="username" required={true}>
        <Input placeholder="OCG portal username" ref={usernameInput} defaultValue={username} />
      </Form.Item>
      <Form.Item label="Password" name="password" required={true}>
        <Input type="password" placeholder="OCG portal password" ref={passwordInput} defaultValue={password} />
      </Form.Item>
      <Row type='flex' justify='end' style={{ gap: '10px' }}>
        <Button onClick={closeOCGCredentialsModal}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={OCGCredsSaveClicked}
        >Save</Button>
      </Row>
    </Form>
  </Modal>);
};

const SACredentialsForm = ({
  closeSACredentialsModal,
  submitSACredentials,
  instituteType,
  institutionsCredentials,
  SACredsSaveClicked,
}) => {
  const usernameInput = React.createRef();
  const passwordInput = React.createRef();
  const { username, password } = (institutionsCredentials && institutionsCredentials.sa) || {};

  const submitData = e => {
    e.preventDefault();
    const usernameVal = usernameInput.current && usernameInput.current.state;
    const passwordVal = passwordInput.current && passwordInput.current.state;
    submitSACredentials({ username: usernameVal && usernameVal.value, password: passwordVal && passwordVal.value, state: instituteType });
  };
  return (<Modal
    visible={true}
    title={'SAWWC Credentials'}
    width={800}
    onCancel={closeSACredentialsModal}
    footer={null}
          >
    <Form onSubmit={submitData}>
      <Form.Item label="Username" name="username" required={true}>
        <Input placeholder="SA portal username" ref={usernameInput} defaultValue={username} />
      </Form.Item>
      <Form.Item label="Password" name="password" required={true}>
        <Input type="password" placeholder="SA portal password" ref={passwordInput} defaultValue={password} />
      </Form.Item>
      <Row type='flex' justify='end' style={{ gap: '10px' }}>
        <Button onClick={closeSACredentialsModal}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={SACredsSaveClicked}
        >Save</Button>
      </Row>
    </Form>
  </Modal>);
};

const QLDBLUECredentialsForm = ({
  closeQLDBLUECredentialsModal,
  submitQLDBLUECredentials,
  instituteType,
  institutionsCredentials,
  QLDBLUECredsSaveClicked,
}) => {
  const usernameInput = React.createRef();
  const passwordInput = React.createRef();
  const { username, password } = (institutionsCredentials && institutionsCredentials.qldblue) || {};

  const submitData = e => {
    e.preventDefault();
    const usernameVal = usernameInput.current && usernameInput.current.state;
    const passwordVal = passwordInput.current && passwordInput.current.state;
    submitQLDBLUECredentials({ username: usernameVal && usernameVal.value, password: passwordVal && passwordVal.value, state: instituteType });
  };
  return (<Modal
    visible={true}
    title={'QLDBLUE Credentials'}
    width={800}
    onCancel={closeQLDBLUECredentialsModal}
    footer={null}
          >
    <Form onSubmit={submitData}>
      <Form.Item label="Username" name="username" required={true}>
        <Input placeholder="QldBlue portal username" ref={usernameInput} defaultValue={username} />
      </Form.Item>
      <Form.Item label="Password" name="password" required={true}>
        <Input type="password" placeholder="QldBlue portal password" ref={passwordInput} defaultValue={password} />
      </Form.Item>
      <Row type='flex' justify='end' style={{ gap: '10px' }}>
        <Button onClick={closeQLDBLUECredentialsModal}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={QLDBLUECredsSaveClicked}
        >Save</Button>
      </Row>
    </Form>
  </Modal>);
};

const NDWSCredentialsForm = ({
  closeNDWSCredentialsModal,
  submitNDWSCredentials,
  instituteType,
  institutionsCredentials,
  NDWSCredsSaveClicked,
}) => {
  const usernameInput = React.createRef();
  const passwordInput = React.createRef();
  const { username, password } = (institutionsCredentials && institutionsCredentials.ndwsUserCreds) || {};

  const submitData = e => {
    e.preventDefault();
    const usernameVal = usernameInput.current && usernameInput.current.state;
    const passwordVal = passwordInput.current && passwordInput.current.state;
    submitNDWSCredentials({ username: usernameVal && usernameVal.value, password: passwordVal && passwordVal.value, state: instituteType });
  };
  return (<Modal
    visible={true}
    title={'NDWS Safeguard Credentials'}
    width={800}
    onCancel={closeNDWSCredentialsModal}
    footer={null}
          >
    <Form onSubmit={submitData}>
      <Form.Item label="Username" name="username" required={true}>
        <Input placeholder="NDWS portal username" ref={usernameInput} defaultValue={username} />
      </Form.Item>
      <Form.Item label="Password" name="password" required={true}>
        <Input type="password" placeholder="NDWS portal password" ref={passwordInput} defaultValue={password} />
      </Form.Item>
      <Row type='flex' justify='end' style={{ gap: '10px' }}>
        <Button onClick={closeNDWSCredentialsModal}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={NDWSCredsSaveClicked}
        >Save</Button>
      </Row>
    </Form>
  </Modal>);
};

const NDWSDefaultsForm = ({
  closeNDWSDefaultsModal,
  submitNDWSDefaults,
  instituteType,
  NDWSDefaultsSaveClicked,
  institutionsCredentials,
}) => {
  const orgNameInput = React.createRef();
  const { orgName } = (institutionsCredentials && institutionsCredentials[instituteType]) || {};

  const submitData = e => {
    e.preventDefault();
    const orgNameVal = orgNameInput.current && orgNameInput.current.state;
    if (!orgNameVal) {
      message.warning('Unable to save defaults. Please fill organisation field!');
      return;
    }
    submitNDWSDefaults({ orgName: orgNameVal && orgNameVal.value });
  };
  return (<Modal
    visible={true}
    title={'NDWS Safeguard Defaults'}
    width={800}
    onCancel={closeNDWSDefaultsModal}
    footer={null}
          >
    <Form onSubmit={submitData}>
      <Form.Item label="Organisation Name" name="orgName" required={true}>
        <Input placeholder="NDWS portal organisation" ref={orgNameInput} defaultValue={orgName}/>
      </Form.Item>
      <Row type='flex' justify='end' style={{ gap: '10px' }}>
        <Button onClick={closeNDWSDefaultsModal}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={NDWSDefaultsSaveClicked}
        >Save</Button>
      </Row>
    </Form>
  </Modal>);
};

const NSWOneTimeCheckDefaultsForm = ({
  form,
  closeNSWOneTimeCheckDefaultsModal,
  submitNSWOneTimeCheckDefaults,
  instituteType,
  NSWOneTimeCheckDefaultsSaveClicked,
  institutionsCredentials,
}) => {
  const { orgName, orgPhoneNumber } = (institutionsCredentials && institutionsCredentials[instituteType]) || {};
  const { getFieldDecorator } = form;

  const submitData = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      const { nswOneTimeCheckDefaults: data } = values || {};
      if (err) {
        message.error('Please address the validation issues below!');
        return;
      }
      submitNSWOneTimeCheckDefaults({ orgName: data && data.orgName, orgPhoneNumber: data && data.orgPhoneNumber });
    });
  };
  return (<Modal
    visible={true}
    title={'NSW One Time Check Defaults'}
    width={800}
    onCancel={closeNSWOneTimeCheckDefaultsModal}
    footer={null}
          >
    <Form form={form} onSubmit={submitData}>
      <Form.Item label="Requesting Individual's Name" name="orgName">
        {getFieldDecorator('nswOneTimeCheckDefaults.orgName', {
          initialValue: orgName,
          rules: [
            {
              required: true,
              message: 'Name is required!',
            },
          ],
        })(
          <Input placeholder="Enter individual's name"/>,
        )}
      </Form.Item>
      <Form.Item label="Requesting Individual's Phone Number" name="orgPhoneNumber">
        {getFieldDecorator('nswOneTimeCheckDefaults.orgPhoneNumber', {
          initialValue: orgPhoneNumber,
          rules: [
            {
              required: true,
              message: 'Phone number is required!',
            },
          ],
        })(
          <Input placeholder="Enter individual's phone number"/>,
        )}
      </Form.Item>
      <Row type='flex' justify='end' style={{ gap: '10px' }}>
        <Button onClick={closeNSWOneTimeCheckDefaultsModal}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={NSWOneTimeCheckDefaultsSaveClicked}
        >Save</Button>
      </Row>
    </Form>
  </Modal>);
};

@withContentLayout
class ProviderSettingsPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.footerRef = React.createRef();
    this.state = {
      showNDWSCreds: false,
      showNDWSDefaults: false,
      NDWSCredsSaveClicked: false,
      NDWSDefaultsSaveClicked: false,
      showOCG: false,
      showSA: false,
      showQLDBLUECreds: false,
      showQLDBLUEDefaults: false,
      currentType: null,
      OCGCredsSaveClicked: false,
      SACredsSaveClicked: false,
      QLDBLUECredsSaveClicked: false,
      QLDBLUEDefaultsSaveClicked: false,
      QLDBLUEDefaultEmpType: null,
      QLDBLUEDefaultAppType: null,
      QLDBLUEDefaultStateSet: false,
      showNSWOneTimeCheckDefaults: false,
      NSWOneTimeCheckDefaultsSaveClicked: false,
      credentialStatuses: {
        nsw: false,
        qldblue: false,
        sa: false,
      },
    };
  }

  componentWillMount() {
    if (this.props.organizationId) {
      this.props.fetchOrganization(this.props.organizationId);
      this.props.fetchOrganizationEmail(this.props.organizationId);
      this.props.fetchTags(this.props.organizationId);
    }
  }

  getCategories = () => NOTIFICATION_CATS_BASE.map(base => ({
    ...base,
    tag: base.getTag(this.props.tagsItems),
  }))

  componentDidUpdate(prevProps) {
    if (prevProps.organizationId !== this.props.organizationId) {
      this.props.fetchOrganization(this.props.organizationId);
      this.props.fetchOrganizationEmail(this.props.organizationId);
      this.props.fetchTags(this.props.organizationId);
    }
    if (prevProps.organizationData !== this.props.organizationData) {
      const orgData = this.props.organizationData.toJSON();
      this.props.form.setFieldsValue({
        institutionsCredentials: orgData.institutionsCredentials || {},
      });
    }
  }

  saCredsItem = (institutionsCredentials, getFieldDecorator, form) => {
    const emailProps = {
      stateName: 'sa',
      institutionsCredentials: institutionsCredentials || {},
      submitClicked: this.state.SACredsSaveClicked,
      onShow: () => this.onShowSACredentialsForm('sa'),
    };
    return getFieldDecorator('sa', {})(
      <div key={'sa'}>
        <DoCEmail
          key={'sa'}
          {...emailProps}
          form={form}
        />
      </div>,
    );
  }

  saSyncItem = (institutionsCredentials, getFieldDecorator, form, patchOrganizationInProgress = false) => {
    const emailProps = {
      stateName: 'saSync',
      institutionsCredentials: institutionsCredentials || {},
      submitClicked: patchOrganizationInProgress,
    };
    return getFieldDecorator('institutionsCredentials.sa.saSync', {})(
      <div key={'saSync'}>
        <DoCEmail
          key={'saSync'}
          {...emailProps}
          form={form}
        />
      </div>,
    );
  }

  qldblueCredsItem = (institutionsCredentials, getFieldDecorator, form) => {
    let defaultsSet = false;
    if (institutionsCredentials.qldblueLinkage) {
      const { appType = false, empType = false, orgName = false } = institutionsCredentials.qldblueLinkage;
      defaultsSet = appType && empType && orgName;
    }
    const emailProps = {
      stateName: 'qldblue',
      institutionsCredentials: institutionsCredentials || {},
      submitClicked: this.state.QLDBLUECredsSaveClicked,
      onShow: () => this.onShowQLDBLUECredentialsForm('qldblue'),
      otherServices: [{
        name: 'Blue Card Services Linking',
        actionLabel: 'Update Default Settings',
        statusColor: defaultsSet ? 'green' : 'grey',
        statusIcon: defaultsSet ? 'check-circle' : 'question-circle',
        action: this.onShowQLDBLUEDefaultsForm,
      }],
    };
    return getFieldDecorator('qldblue', {})(
      <div key={'qldblue'}>
        <DoCEmail key={'qldblue'} {...emailProps} form={form} />
      </div>,
    );
  }

  ndwsImportItem = (institutionsCredentials, getFieldDecorator, form, patchOrganizationInProgress) => {
    const emailProps = {
      stateName: 'ndwsImport',
      emailAddress: null,
      institutionsCredentials: institutionsCredentials || {},
      submitClicked: patchOrganizationInProgress,
    };
    return getFieldDecorator('institutionsCredentials.ndws.importEnabled', {})(
      <div key={'ndwsImport'}>
        <DoCEmail key={'ndwsImport'} {...emailProps} form={form} />
      </div>,
    );
  }

  ndwsSyncItem = (institutionsCredentials, getFieldDecorator, form, patchOrganizationInProgress) => {
    const emailProps = {
      stateName: 'ndwsSync',
      emailAddress: null,
      institutionsCredentials: institutionsCredentials || {},
      submitClicked: patchOrganizationInProgress,
    };
    return getFieldDecorator('institutionsCredentials.ndws.syncEnabled', {})(
      <div key={'ndwsSync'}>
        <DoCEmail key={'ndwsSync'} {...emailProps} form={form} />
      </div>,
    );
  }

  ndwsCredsItem = (institutionsCredentials, getFieldDecorator, form) => {
    let defaultsSet = false;
    if (institutionsCredentials.ndwsUserCreds) {
      const { appType = false, empType = false, orgName = false } = institutionsCredentials.ndwsUserCreds;
      defaultsSet = appType && empType && orgName;
    }
    const emailProps = {
      stateName: 'ndwsUserCreds',
      institutionsCredentials: institutionsCredentials || {},
      submitClicked: this.state.NDWSCredsSaveClicked,
      onShow: () => this.onShowNDWSCredentialsForm('ndwsUserCreds'),
      otherServices: [{
        name: 'NDWS Safeguard Defaults',
        actionLabel: 'Update Default Settings',
        action: () => this.onShowNDWSDefaultsForm('ndws'),
        statusColor: defaultsSet ? 'green' : 'grey',
        statusIcon: defaultsSet ? 'check-circle' : 'question-circle',
      }],
    };
    return getFieldDecorator('ndwsUserCreds', {})(
      <div key={'ndwsUserCreds'}>
        <DoCEmail key={'ndwsUserCreds'} {...emailProps} form={form} />
      </div>,
    );
  }

  handleSubmit = ev => {
    ev.preventDefault();
    this.props.form.validateFields(async (errors, values) => {
      if (errors) return;

      await this.props.patchOrganization(this.props.organizationId, _.omit(values, ['notify']));

      const doneEmail = window.SERVER_DATA.allowedStatesForOrgEmails.filter(async item => {
        if (values && values[item.type]) {
          return await this.props.postOrganizationEmail(this.props.organizationId, { type: item.type });
        }
        return null;
      });

      if (doneEmail) {
        await this.props.fetchOrganizationEmail(this.props.organizationId);
      }
    });
  }

  onCopyEmailToClipBoard = email => navigator.clipboard.writeText(email);

  onShowOCGCredentialsForm = type => {
    this.setState({ showOCG: true, currentType: type });
  }

  closeOCGCredentialsModal = () => {
    this.setState({ showOCG: false, currentType: null });
  }

  onShowSACredentialsForm = type => {
    this.setState({ showSA: true, currentType: type });
  }

  closeSACredentialsModal = () => {
    this.setState({ showSA: false, currentType: null });
  }

  onShowQLDBLUECredentialsForm = type => {
    this.setState({ showQLDBLUECreds: true, currentType: type });
  }

  onShowNDWSCredentialsForm = type => {
    this.setState({ showNDWSCreds: true, currentType: type });
  }

  closeNDWSCredentialsModal = type => {
    this.setState({ showNDWSCreds: false, currentType: null });
  }

  closeQLDBLUECredentialsModal = () => {
    this.setState({ showQLDBLUECreds: false, currentType: null });
  }

  onShowQLDBLUEDefaultsForm = () => {
    this.setState({ showQLDBLUEDefaults: true });
  }

  onShowNDWSDefaultsForm = type => {
    this.setState({ showNDWSDefaults: true, currentType: type });
  }

  onShowNSWOneTimeCheckDefaultsForm = type => {
    this.setState({ showNSWOneTimeCheckDefaults: true, currentType: type });
  }

  closeNSWOneTimeCheckDefaultsModal = () => {
    this.setState({ showNSWOneTimeCheckDefaults: false });
  }

  closeNDWSDefaultsModal = () => {
    this.setState({ showNDWSDefaults: false });
  }

  closeQLDBLUEDefaultsModal = () => {
    this.setState({ showQLDBLUEDefaults: false });
  }

  submitOCGCredentials = async data => {
    this.setState({ OCGCredsSaveClicked: true });
    let orgId = this.props.organizationId;
    setTimeout(() => {
      this.closeOCGCredentialsModal();
    }, 3000);
    await this.props.postOrganizationCredentials(orgId, data)
      .then(result => {
        const content = JSON.parse(result);
        orgId = content.organizationId;
        this.props.fetchOrganization(orgId)
          .then(result => {
            if (content && content.success) {
              message.success('Credentials saved successfully');
            } else {
              message.error('Unable to save credentials. Please try again!');
            }
          }).catch(err => {
            message.error('Unable to fetch saved credentials. Please try again!');
          });
        this.setState({ OCGCredsSaveClicked: false });
      })
      .catch(err => {
        console.error(err);
        this.setState({ OCGCredsSaveClicked: false });
      });
  }

  submitSACredentials = async data => {
    this.setState({ SACredsSaveClicked: true });
    let orgId = this.props.organizationId;
    setTimeout(() => {
      this.closeSACredentialsModal();
    }, 3000);
    await this.props.postOrganizationCredentials(orgId, data)
      .then(result => {
        const content = JSON.parse(result);
        orgId = content.organizationId;
        this.props.fetchOrganization(orgId)
          .then(result => {
            if (content && content.success) {
              message.success('Credentials saved successfully');
            } else {
              message.error('Unable to save credentials. Please try again!');
            }
          }).catch(err => {
            message.error('Unable to fetch saved credentials. Please try again!');
          });
        this.setState({ SACredsSaveClicked: false });
      })
      .catch(err => {
        this.setState({ SACredsSaveClicked: false });
      });
  }

  submitQLDBLUECredentials = async data => {
    this.setState({ QLDBLUECredsSaveClicked: true });
    let orgId = this.props.organizationId;
    setTimeout(() => {
      this.closeQLDBLUECredentialsModal();
    }, 3000);
    await this.props.postOrganizationCredentials(orgId, data)
      .then(result => {
        const content = JSON.parse(result);
        orgId = content.organizationId;
        this.props.fetchOrganization(orgId)
          .then(result => {
            const res = result.data.institutionsCredentials.qld[this.props.userId];
            if (res.lastCheckedStatus === 'pending' || res.lastCheckedStatus === 'success') {
              message.success('Credentials saved successfully');
              return;
            }
            message.error('Unable to save credentials. Please try again!');
          }).catch(err => {
            message.error('Unable to fetch saved credentials. Please try again!');
          });
        this.setState({ QLDBLUECredsSaveClicked: false });
      })
      .catch(err => {
        this.setState({ QLDBLUECredsSaveClicked: false });
      });
  }

  submitQldBlueDefaults = async data => {
    this.setState({ QLDBLUEDefaultsSaveClicked: true });
    const orgId = this.props.organizationId;
    try {
      const result = await this.props.postOrganizationQldDefaults(orgId, data);
      try {
        await this.props.fetchOrganization(orgId);
        if (result && result.success) {
          setTimeout(() => this.closeQLDBLUEDefaultsModal(), 1000);
          message.success('QldBlue Defaults saved successfully');
        } else {
          message.error('Unable to save QldBlue Defaults. Please try again!');
        }
        this.setState({ QLDBLUEDefaultsSaveClicked: false });
      } catch (err) {
        message.error('Unable to fetch saved QldBlue Defaults. Please try again!');
      }
    } catch (err) {
      this.setState({ QLDBLUEDefaultsSaveClicked: false });
    }
  }

  submitNDWSCredentials = async data => {
    this.setState({ NDWSCredsSaveClicked: true });
    let orgId = this.props.organizationId;
    setTimeout(() => {
      this.closeNDWSCredentialsModal();
    }, 3000);
    await this.props.postOrganizationCredentials(orgId, data)
      .then(result => {
        const content = JSON.parse(result);
        orgId = content.organizationId;
        this.props.fetchOrganization(orgId)
          .then(result => {
            const res = result.data.institutionsCredentials.ndws[this.props.userId];
            if (res.lastCheckedStatus === 'pending' || res.lastCheckedStatus === 'success') {
              message.success('Credentials saved successfully');
              return;
            }
            message.error('Unable to save credentials. Please try again!');
          }).catch(err => {
            message.error('Unable to fetch saved credentials. Please try again!');
          });
        this.setState({ NDWSCredsSaveClicked: false });
      })
      .catch(err => {
        console.error(err);
        this.setState({ NDWSCredsSaveClicked: false });
      });
  }

  submitNDWSDefaults = async data => {
    this.setState({ NDWSDefaultsSaveClicked: true });
    const orgId = this.props.organizationId;
    try {
      const result = await this.props.postOrganizationNDWSDefaults(orgId, data);
      try {
        await this.props.fetchOrganization(orgId);
        if (result && result.success) {
          setTimeout(() => this.closeNDWSDefaultsModal(), 1000);
          message.success('NDWS Defaults saved successfully');
        } else {
          message.error('Unable to save NDWS Defaults. Please try again!');
        }
        this.setState({ NDWSDefaultsSaveClicked: false });
      } catch (err) {
        message.error('Unable to fetch saved NDWS Defaults. Please try again!');
      }
    } catch (err) {
      this.setState({ NDWSDefaultsSaveClicked: false });
    }
  }

  submitNSWOneTimeCheckDefaults = async data => {
    this.setState({ NSWOneTimeCheckDefaultsSaveClicked: true });
    const orgId = this.props.organizationId;
    try {
      const result = await this.props.postOrganizationNSWOneTimeCheckDefaults(orgId, data);
      try {
        await this.props.fetchOrganization(orgId);
        if (result && result.success) {
          message.success('NSW One Time Check Defaults saved successfully');
          this.closeNSWOneTimeCheckDefaultsModal();
        } else {
          const errObj = _.get(result, ['data', '_errors'], null);

          if (errObj) {
            Object.entries(errObj).forEach(
              ([_, value]) => message.error(value),
            );
          } else message.error('Unable to save NSW One Time Check Defaults. Please try again!');
        }
        this.setState({ NSWOneTimeCheckDefaultsSaveClicked: false });
      } catch (err) {
        message.error('Unable to fetch saved NSW One Time Check Defaults. Please try again!');
      }
    } catch (err) {
      this.setState({ NSWOneTimeCheckDefaultsSaveClicked: false });
    }
  }

  QLDBLUESetDefaultsAppType = appType => this.setState({ QLDBLUEDefaultAppType: appType });

  QLDBLUESetDefaultsEmpType = empType => this.setState({ QLDBLUEDefaultEmpType: empType });

  QLDBLUEGetDefaultsEmpType = () => this.state.QLDBLUEDefaultEmpType;

  QLDBLUEGetDefaultsAppType = () => this.state.QLDBLUEDefaultAppType;

  QLDBLUESetDefaultPresetState = () => this.setState({ QLDBLUEDefaultStateSet: true });

  QLDBLUEGetDefaultPresetState = () => this.state.QLDBLUEDefaultStateSet;

  QLDBlueDefaultsForm({
    closeQLDBLUEDefaultsModal,
    submitQldBlueDefaults,
    QLDBLUEGetDefaultPresetState,
    QLDBLUESetDefaultPresetState,
    QLDBLUESetDefaultsAppType,
    QLDBLUESetDefaultsEmpType,
    QLDBLUEGetDefaultsAppType,
    QLDBLUEGetDefaultsEmpType,
    instituteType,
    institutionsCredentials,
    QLDBLUEDefaultsSaveClicked,
  }) {
    const orgNameInput = React.createRef();
    const applicantTypeSelect = React.createRef();
    const employmentTypeSelect = React.createRef();
    const { orgName, appType, empType } = (institutionsCredentials && institutionsCredentials.qldblueLinkage) || {};

    if (!QLDBLUEGetDefaultPresetState()) {
      QLDBLUESetDefaultsAppType(appType);
      QLDBLUESetDefaultsEmpType(empType);
      QLDBLUESetDefaultPresetState();
    }

    const submitData = e => {
      e.preventDefault();
      const orgNameVal = orgNameInput.current && orgNameInput.current.state;
      const appTypeVal = QLDBLUEGetDefaultsAppType();
      const empTypeVal = QLDBLUEGetDefaultsEmpType();
      if (orgNameVal === null || appTypeVal === null || empTypeVal === null) {
        message.warning('Unable to save defaults. Please fill out all fields!');
        return;
      }
      submitQldBlueDefaults({ orgName: orgNameVal && orgNameVal.value, appType: appTypeVal, empType: empTypeVal, state: instituteType });
    };
    return (<Modal
      visible={true}
      title={'QLDBLUE Defaults'}
      width={800}
      onCancel={closeQLDBLUEDefaultsModal}
      footer={null}
            >
      <Form onSubmit={submitData}>
        <Form.Item>
          <span>Organisation Name</span>
          <Input ref={orgNameInput} defaultValue={orgName} />
        </Form.Item>
        <Form.Item>
          <span>Applicant Type</span>
          <Select ref={applicantTypeSelect} defaultValue={appType} onChange={e => QLDBLUESetDefaultsAppType(e)}>
            <Select.Option value="Paid">Paid</Select.Option>
            <Select.Option value="Student">Student</Select.Option>
            <Select.Option value="Volunteer">Volunteer</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <span>Employment Type</span>
          <Select ref={employmentTypeSelect} defaultValue={empType} onChange={e => QLDBLUESetDefaultsEmpType(e)}>
            <Select.Option value="Child accommodation services including home stays">Child accommodation services including home stays</Select.Option>
            <Select.Option value="Child care">Child care</Select.Option>
            <Select.Option value="Churches, clubs and associations">Churches, clubs and associations</Select.Option>
            <Select.Option value="Disability Services">Disability Services</Select.Option>
            <Select.Option value="Foster Care: Adult Member">Foster Care: Adult Member</Select.Option>
            <Select.Option value="Health, counselling and support services (including disability services)">Health, counselling and support services (including disability services)</Select.Option>
            <Select.Option value="Kinship Care: Adult Member">Kinship Care: Adult Member</Select.Option>
            <Select.Option value="Licensed care services">Licensed care services</Select.Option>
            <Select.Option value="Private teaching, coaching and tutoring">Private teaching, coaching and tutoring</Select.Option>
            <Select.Option value="Religious representatives">Religious representatives</Select.Option>
            <Select.Option value="Schools, other than EQ staff or volunteers (e.g. P&C, cleaner)">Schools, other than EQ staff or volunteers (e.g. P&C, cleaner)</Select.Option>
            <Select.Option value="Sport and active recreation">Sport and active recreation</Select.Option>
          </Select>
        </Form.Item>
        <Row type='flex' justify='end' style={{ gap: '10px' }}>
          <Button onClick={closeQLDBLUEDefaultsModal}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={QLDBLUEDefaultsSaveClicked}
          >Save</Button>
        </Row>
      </Form>
    </Modal >);
  }

  render() {
    const {
      userId,
      form,
      fetchOrganizationInProgress, fetchOrganizationFailed,
      patchOrganizationInProgress, patchOrganizationFailed,
      organizationEmails,
      institutionsCredentials: inCreds,
      settings,
    } = this.props;
    const { getFieldDecorator } = form;
    const institutionsCredentials = (inCreds && inCreds.toJSON()) || form.getFieldValue('institutionsCredentials') || {};
    const orgSettings = (settings && settings.toJSON()) || {};

    if (institutionsCredentials.qld && institutionsCredentials.qld[userId]) {
      institutionsCredentials.qldblue = institutionsCredentials.qld[userId];
    }

    if (institutionsCredentials.ndws && institutionsCredentials.ndws[userId]) {
      institutionsCredentials.ndwsUserCreds = institutionsCredentials.ndws[userId];
    }

    const orgMode = orgSettings && orgSettings.orgMode === 'oneoff' ? 'oneoff' : 'ongoing';

    const customPanelStyle = {
      background: '#f7f7f7',
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: 'hidden',
      width: '100%',
    };

    const { Panel } = Collapse;

    const getCredStatusIcon = stateName => {
      const { color, icon } = determineCredsStatus(stateName, institutionsCredentials);
      return (
        <Icon
          type={icon}
          style={{ color, fontSize: '20px' }}
          onClick={event => {
            event.stopPropagation();
          }}
        />
      );
    };

    return (<Spin spinning={fetchOrganizationInProgress}>
      <BreadcrumbLevel text="Settings" />
      <BreadcrumbLevel text="Provider Settings" />
      {fetchOrganizationFailed
        ? <Alert message="Couldn't get your organisation's settings right now!" /> : null}
      {patchOrganizationFailed
        ? <Alert message="Couldn't save your organisation's settings right now!" closable /> : null}
      <Form onSubmit={this.handleSubmit}>
        <h2>Provider Settings</h2>

        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
          style={{ marginTop: '1.2rem' }}
        >
          <Panel header="New South Wales" key="1" style={customPanelStyle} extra={getCredStatusIcon('nsw')}>
            <Form.Item>
              {organizationEmails && organizationEmails.map(indexOfState => {
                let defaultsSet = false;
                if (institutionsCredentials.nswOneTimeCheck) {
                  const { orgName = '', orgEmail = '', orgPhoneNumber = '' } = institutionsCredentials.nswOneTimeCheck;
                  defaultsSet = orgName && orgEmail && orgPhoneNumber;
                }
                if (indexOfState.email && indexOfState.email !== null) {
                  const emailProps = {
                    stateName: indexOfState.type || null,
                    emailAddress: indexOfState.email || null,
                    institutionsCredentials: institutionsCredentials || {},
                    submitClicked: this.state.OCGCredsSaveClicked,
                    onCopy: () => this.onCopyEmailToClipBoard(indexOfState.email),
                    onShow: () => this.onShowOCGCredentialsForm(indexOfState.type),
                    otherServices: [{
                      name: 'NSW One Time Check Verifier Details',
                      actionLabel: 'Update Default Settings',
                      action: () => this.onShowNSWOneTimeCheckDefaultsForm('nswOneTimeCheck'),
                      statusColor: defaultsSet ? 'green' : 'grey',
                      statusIcon: defaultsSet ? 'check-circle' : 'question-circle',
                    }],
                    orgMode,
                  };
                  return <DoCEmail key='nsw' {...emailProps} form={form} />;
                }
                return (
                  <div key={indexOfState.type}>
                    <p style={{ display: 'inline' }}>Enable OCG Checks</p>
                    {getFieldDecorator(indexOfState.type, {
                      valuePropName: 'checked',
                    })(
                      <Switch style={{ marginLeft: '20px', float: 'right' }} />,
                    )}
                  </div>
                );
              })}
            </Form.Item>
          </Panel>
          <Panel header="Queensland" key="2" style={customPanelStyle} extra={getCredStatusIcon('qldblue')}>
            <Form.Item>
              {this.qldblueCredsItem(institutionsCredentials, getFieldDecorator, form)}
            </Form.Item>
          </Panel>
          <Panel header="South Australia" key="3" style={customPanelStyle} extra={getCredStatusIcon('sa')}>
            <Form.Item>
              {this.saCredsItem(institutionsCredentials, getFieldDecorator, form)}
            </Form.Item>
            {_.get(institutionsCredentials, ['sa', 'lastSaved']) && (
              <Form.Item>
                {this.saSyncItem(institutionsCredentials, getFieldDecorator, form)}
              </Form.Item>
            )}
          </Panel>
          {checkFeature('ndwsImport') && (
            <Panel header="Nationwide" key="4" style={customPanelStyle} extra={getCredStatusIcon('ndwsUserCreds')}>
              <Form.Item>
                {this.ndwsImportItem(institutionsCredentials, getFieldDecorator, form, patchOrganizationInProgress)}
              </Form.Item>
              {checkFeature('ndwsSync') && <Form.Item>
                {this.ndwsSyncItem(institutionsCredentials, getFieldDecorator, form, patchOrganizationInProgress)}
              </Form.Item>}
              <Form.Item>
                {this.ndwsCredsItem(institutionsCredentials, getFieldDecorator, form)}
              </Form.Item>
            </Panel>
          )}
        </Collapse>
        <Row justify="end" type="flex">
          <Col>
            <Button
              style={{ marginTop: '16px' }}
              loading={patchOrganizationInProgress}
              type="primary"
              htmlType="submit"
              size="large"
            >Save</Button>
          </Col>
        </Row>
      </Form>
      {this.state.showOCG && this.state.showOCG === true
        && <OCGCredentialsForm
          closeOCGCredentialsModal={() => this.closeOCGCredentialsModal()}
          submitOCGCredentials={e => this.submitOCGCredentials(e)}
          instituteType={this.state.currentType}
          institutionsCredentials={institutionsCredentials}
          OCGCredsSaveClicked={this.state.OCGCredsSaveClicked}
           />}
      {this.state.showSA && this.state.showSA === true
        && <SACredentialsForm
          closeSACredentialsModal={() => this.closeSACredentialsModal()}
          submitSACredentials={e => this.submitSACredentials(e)}
          instituteType='sa'
          institutionsCredentials={institutionsCredentials}
          SACredsSaveClicked={this.state.SACredsSaveClicked}
           />}
      {this.state.showQLDBLUECreds && this.state.showQLDBLUECreds === true
        && <QLDBLUECredentialsForm
          closeQLDBLUECredentialsModal={() => this.closeQLDBLUECredentialsModal()}
          submitQLDBLUECredentials={e => this.submitQLDBLUECredentials(e)}
          instituteType='qld'
          institutionsCredentials={institutionsCredentials}
          QLDBLUECredsSaveClicked={this.state.QLDBLUECredsSaveClicked}
           />}
      {this.state.showQLDBLUEDefaults && this.state.showQLDBLUEDefaults === true
        && <this.QLDBlueDefaultsForm
          closeQLDBLUEDefaultsModal={() => this.closeQLDBLUEDefaultsModal()}
          submitQldBlueDefaults={e => this.submitQldBlueDefaults(e)}
          QLDBLUEGetDefaultPresetState={() => this.QLDBLUEGetDefaultPresetState()}
          QLDBLUESetDefaultPresetState={() => this.QLDBLUESetDefaultPresetState()}
          QLDBLUEGetDefaultsAppType={() => this.QLDBLUEGetDefaultsAppType()}
          QLDBLUESetDefaultsAppType={e => this.QLDBLUESetDefaultsAppType(e)}
          QLDBLUEGetDefaultsEmpType={() => this.QLDBLUEGetDefaultsEmpType()}
          QLDBLUESetDefaultsEmpType={e => this.QLDBLUESetDefaultsEmpType(e)}
          instituteType='qldblue_linkage'
          institutionsCredentials={institutionsCredentials}
          QLDBLUEDefaultsSaveClicked={this.state.QLDBLUEDefaultsSaveClicked}
           />}
      {this.state.showNDWSCreds && this.state.showNDWSCreds === true
        && <NDWSCredentialsForm
          closeNDWSCredentialsModal={() => this.closeNDWSCredentialsModal()}
          submitNDWSCredentials={e => this.submitNDWSCredentials(e)}
          instituteType='ndws'
          institutionsCredentials={institutionsCredentials}
          NDWSCredsSaveClicked={this.state.NDWSCredsSaveClicked}
           />}
      {this.state.showNDWSDefaults && this.state.showNDWSDefaults === true
        && <NDWSDefaultsForm
          closeNDWSDefaultsModal={() => this.closeNDWSDefaultsModal()}
          submitNDWSDefaults={e => this.submitNDWSDefaults(e)}
          instituteType={this.state.currentType}
          NDWSDefaultsSaveClicked={this.state.NDWSDefaultsSaveClicked}
          institutionsCredentials={institutionsCredentials}
           />}
      {this.state.showNSWOneTimeCheckDefaults && this.state.showNSWOneTimeCheckDefaults === true
        && <NSWOneTimeCheckDefaultsForm
          form = {form}
          closeNSWOneTimeCheckDefaultsModal={() => this.closeNSWOneTimeCheckDefaultsModal()}
          submitNSWOneTimeCheckDefaults={e => this.submitNSWOneTimeCheckDefaults(e)}
          instituteType={this.state.currentType}
          NSWOneTimeCheckDefaultsSaveClicked={this.state.NSWOneTimeCheckDefaultsSaveClicked}
          institutionsCredentials={institutionsCredentials}
           />}
    </Spin>);
  }
}

export const ProviderSettingsPageForm = Form.create()(ProviderSettingsPageComponent);

export const ProviderSettingsPage = connect(
  state => ({
    userId: state.doc.getIn(['authMr', 'id'], null),
    organizationData: state.doc.getIn(['authMr', 'constituent', 'organization'], null),
    tagsItems: state.doc.getIn(['tagsMr', 'items'], null),
    fetchOrganizationInProgress: state.doc.getIn(['http', 'fetchOrganization', 'inProgress'], true),
    fetchOrganizationFailed: state.doc.getIn(['http', 'fetchOrganization', 'failed'], false),
    fetchOrganizationEmailInProgress: state.doc.getIn(['http', 'fetchOrganizationEmail', 'inProgress'], true),
    fetchOrganizationEmailFailed: state.doc.getIn(['http', 'fetchOrganizationEmail', 'failed'], false),
    patchOrganizationInProgress: state.doc.getIn(['http', 'patchOrganization', 'inProgress'], false),
    patchOrganizationFailed: state.doc.getIn(['http', 'patchOrganization', 'failed'], false),
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
    postOrganizationEmailInProgress: state.doc.getIn(['http', 'postOrganizationEmail', 'inProgress'], true),
    postOrganizationEmailFailed: state.doc.getIn(['http', 'postOrganizationEmail', 'failed'], false),
    postOrganizationCredentialsFailed: state.doc.getIn(['http', 'postOrganizationCredentials', 'failed'], false),
    organizationEmails: state.doc.getIn(['authMr', 'constituent', 'organization', 'orgEmails'], null),
    institutionsCredentials: state.doc.getIn(['authMr', 'constituent', 'organization', 'institutionsCredentials'], null),
    settings: state.doc.getIn(['authMr', 'constituent', 'organization', 'settings'], null),
  }),
  actions,
)(ProviderSettingsPageForm);
