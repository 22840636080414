import React, { PureComponent } from 'react';
import moment from 'moment';
import {
  Button, Table, Tooltip, DatePicker, Row, Col, Modal, Checkbox, Icon, Tag,
} from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { BreadcrumbLevel } from '../../BreadcrumbLevel';
import { withContentLayout } from '../../layout/Layout';
import * as actions from '../../../actions';
import { stripHints } from '../../../utils';
import { FilterSearch } from '../ConstituentListPage/FilterSearch';


const { confirm } = Modal;

const DEFAULT_ICR_FILTERS = ['queued', 'pending', 'review', 'failed'];
const COMPLETED_ITEMS_FILTERS = ['completed', 'cancelled'];

const ICR_STATUS_FILTERS = [
  {
    text: 'Queued',
    value: 'queued',
  },
  {
    text: 'Pending',
    value: 'pending',
  },
  {
    text: 'Completed',
    value: 'completed',
  },
  {
    text: 'Review',
    value: 'review',
  },
  {
    text: 'Failed',
    value: 'failed',
  },
  {
    text: 'Cancelled',
    value: 'cancelled',
  },
];

const ACTION_ICON_CONFIG = {
  resend: {
    tooltipTitle: 'Resend request',
    actionStr: 'resend_email',
    allowedStatus: ['queued', 'pending', 'review', 'failed'],
    iconType: 'mail',
    iconColor: '#C88242',
  },
  update: {
    tooltipTitle: 'Update request',
    actionStr: 'update',
    allowedStatus: ['pass', 'completed', 'review', 'failed', 'cancelled'],
    iconType: 'cloud-sync',
    iconColor: '#6CB4EE',
  },
  onboard: {
    tooltipTitle: 'Onboard constituent',
    actionStr: 'onboard_constituent',
    allowedStatus: ['completed'],
    iconType: 'user-add',
    iconColor: '#389E0D',
    condition: (record, settings = {}) => settings && settings.id && settings.name,
  },
  cancel: {
    tooltipTitle: 'Cancel request',
    actionStr: 'cancel_request',
    allowedStatus: ['queued', 'pending', 'review'],
    iconType: 'close-square',
    iconColor: '#FD5C63',
  },
};

const shouldRenderStatusIcon = (item, status, record, onboardingSettings) => ACTION_ICON_CONFIG[item].allowedStatus.includes(status)
  && (ACTION_ICON_CONFIG[item].condition
    ? ACTION_ICON_CONFIG[item].condition(record, onboardingSettings)
    : true);


const RenderStatusIcon = ({ status, record, actionHandler, onboardingSettings }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {
    Object.keys(ACTION_ICON_CONFIG).map(item => (
      shouldRenderStatusIcon(item, status, record, onboardingSettings) && <Tooltip placement='top' key={item} title={ACTION_ICON_CONFIG[item].tooltipTitle}>
        <Icon onClick={() => {
          actionHandler({
            ...record, action: item,
          });
        }} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer', color: ACTION_ICON_CONFIG[item].iconColor }} type={ACTION_ICON_CONFIG[item].iconType}
        />
      </Tooltip>
    ))
  }
  </div>
);

@withContentLayout
class IcrTrackingPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      fromDate: null,
      toDate: null,
      includeCompletedItems: false,
      filtersInfo: {
        status: DEFAULT_ICR_FILTERS,
      },
      sorterInfo: {},
      pagination: {},
    };
  }

  setQuery = searchQuery => {
    this.setState({ searchQuery });
  }

  buildFilterOptions = (pagination, filters, sorter) => {
    const { organizationId } = this.props;
    const { fromDate, toDate, includeCompletedItems, searchQuery } = this.state;

    const filteredStatus = new Set((filters.status && filters.status.length) ? [...filters.status] : DEFAULT_ICR_FILTERS);
    if (includeCompletedItems) COMPLETED_ITEMS_FILTERS.forEach(item => filteredStatus.add(item));

    const order = ['ascend', 'asc'].includes(sorter.order) ? 'asc' : 'desc';
    const offset = (pagination.current * 10) - 10 || 0;

    const opts = {
      organizationId,
      from_date: fromDate || null,
      to_date: toDate || null,
      constituent_name: searchQuery || null,
      status: [...filteredStatus],
      order: sorter.order ? `id,${order}` : 'id,desc',
      offset,
    };

    this.setState({
      pagination,
      filtersInfo: {
        ...filters,
        status: [...filteredStatus],
      },
      sorterInfo: sorter,
    });

    Object.keys(opts).forEach(key => (opts[key] == null || (Array.isArray(opts[key]) && opts[key].length === 0)) && delete opts[key]);

    return opts;
  }

  fetchInfoCaptureRequestsNext = (pagination, filters, sorter) => {
    const opts = this.buildFilterOptions(pagination, filters, sorter);
    this.props.fetchInfoCaptureRequests(opts);
  };

  disableFuturedDate = current => current && current > moment().endOf('day');

  onDateChange = (dates, datesStr) => {
    if (!datesStr && !datesStr.length === 2) return;
    const [fromDate, toDate] = datesStr;
    const fromDateUtc = fromDate ? moment(fromDate).startOf('day').toDate().toISOString() : null;
    const toDateUtc = toDate ? moment(toDate).endOf('day').toDate().toISOString() : null;
    this.setState({ fromDate: fromDateUtc, toDate: toDateUtc });
  }

  onCompletedItemsChange = ev => {
    this.setState({ includeCompletedItems: ev.target.checked });
  }

  handleRefresh = () => {
    const { filtersInfo: filters, sorterInfo: sorter, pagination } = this.state;
    const opts = this.buildFilterOptions(pagination, filters, sorter);
    this.props.fetchInfoCaptureRequests(opts);
  }

  componentDidMount() {
    if (this.props.organizationId) {
      this.props.fetchInfoCaptureRequests({
        organizationId: this.props.organizationId,
        status: DEFAULT_ICR_FILTERS,
      });
    }
  }

  getTagColor = color => {
    if (!color) return null;

    let response;

    switch (color) {
      case 'green':
        response = 'green';
        break;
      case 'red':
        response = 'red';
        break;
      case 'yellow':
        response = 'gold';
        break;
      default:
        response = null;
        break;
    }

    return response;
  }

  getContent = (record, action, onboardingSettings = {}) => {
    const ackText = action => {
      switch (action) {
        case 'update':
          return <p>Acknowledging this will <b>sync the result from Oho platform to your corresponding HR system</b></p>;
        case 'resend':
          return <p>Acknowledging this will <b>resend the email to this person so they can upload their accreditation informations</b></p>;
        case 'cancel':
          return <p>Acknowledging this will <b>cancel the screening package for this person</b></p>;
        case 'onboard':
          return <p>Acknowledging this will <b>move this person to {onboardingSettings.name ? `organization "${onboardingSettings.name}"` : 'the configured onboarding organisation instance'}</b></p>;
        default:
          return 'unknown action';
      }
    };
    const confirmText = (action, record) => {
      switch (action) {
        case 'onboard':
          return <p>Please confirm you would like to onboard for <b>{stripHints(record.constituent.fullName)}</b> having completed package <b>{record.screeningPackage.name}</b></p>;
        default:
          return <p>You are confirming to <b>{action}</b> for the screening package of <b>{record.screeningPackage.name}</b> for <b>{stripHints(record.constituent.fullName)}</b></p>;
      }
    };
    return (
      <>
        {confirmText(action, record)}
        {ackText(action)}
      </>
    );
  }

  handleInfoCaptureRequestAction = record => {
    const { action, constituentId, id } = record;
    const { actionStr } = ACTION_ICON_CONFIG[action];
    const { onboardingOrganizationSettings } = this.props;
    const onboardingSettings = onboardingOrganizationSettings && onboardingOrganizationSettings.toJS();
    const handleOk = async () => {
      const { organizationId } = this.props;
      await this.props.infoCaptureRequestAction({ action: actionStr, constituentId, icrId: id, organizationId });
      const { filtersInfo: filters, sorterInfo: sorter, pagination } = this.state;
      const opts = this.buildFilterOptions(pagination, filters, sorter);
      this.props.fetchInfoCaptureRequests(opts);
    };
    confirm({
      title: `Do you want to ${action} this request?`,
      content: this.getContent(record, action, onboardingSettings),
      onOk() {
        handleOk();
      },
    });
  }

  renderICRChecks = (record = []) => {
    if (!record && !record.length) return null;

    return record.map(item => {
      const accType = _.get(item, 'accreditation.type', null);
      if (!accType) {
        const screeningGroupName = _.get(item, 'screeningGroup.name', null);
        const screeningGroupCode = _.get(item, 'screeningGroup.code', null);
        if (!screeningGroupName) return null;
        return (<Tooltip title={screeningGroupCode} placement='top' >
          <Tag
            style={{ fontSize: '16px', border: '1px dashed', borderRadius: '2px' }}
          ><span style={{ fontWeight: 'bold' }}>{String(screeningGroupName).toLowerCase()}</span>
          </Tag>
        </Tooltip>
        );
      }
      const statusFlags = _.get(item, 'accreditation.claims[0].statusFlags', []);
      const statusColor = _.get(item, 'accreditation.claims[0].statusColor', null);
      const registryResponse = _.get(item, 'accreditation.registryResponse.response', null);

      const tooltipContent = (
        <span>
          {statusFlags.length > 0 && <p>Status Flags: {statusFlags.join(', ')}</p>}
          {registryResponse && <p>Response: {registryResponse}</p>}
        </span>
      );

      return (<Tooltip title={tooltipContent} placement='top' >
        <Tag
          color={this.getTagColor(statusColor)}
          style={{ fontSize: '16px' }}
        ><span style={{ fontWeight: 'bold' }}>{String(accType).toLowerCase()}</span>
        </Tag>
      </Tooltip>
      );
    });
  }

  renderStatus = (record, onboardingSettings) => {
    if (!record) return null;
    const { status } = record;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '80px' }}>{_.capitalize(status)}</div>
        <RenderStatusIcon record={record} status={status} actionHandler={this.handleInfoCaptureRequestAction} onboardingSettings={onboardingSettings}/>
      </div>
    );
  }


  render() {
    const { fetchInfoCaptureRequestsInProgress, InfoCaptureRequestItems, InfoCaptureRequestTotal, onboardingOrganizationSettings } = this.props;
    const items = InfoCaptureRequestItems ? InfoCaptureRequestItems.toJS() : [];
    const { includeCompletedItems, pagination, filtersInfo } = this.state;
    pagination.total = InfoCaptureRequestTotal;
    const onboardingSettings = onboardingOrganizationSettings && onboardingOrganizationSettings.toJS();
    return (
      <div>
        <BreadcrumbLevel text="Info Capture Request Tracking" />
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <FilterSearch query={this.state.searchQuery} allowSearchOnKeyChange setQuery={this.setQuery} onEnter={this.handleRefresh} />
          </Col>
          <Col style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '26px', width: '240px' }}>
              <DatePicker.RangePicker
                ranges={{
                  Today: [moment().startOf('day'), moment().endOf('day')],
                  'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                  'This Month': [moment().startOf('month'), moment().endOf('day')],
                }}
                onChange={this.onDateChange}
                disabledDate={this.disableFuturedDate}
              />
            </div>
            <div style={{ marginRight: '16px' }}>
              <Checkbox
                onChange={this.onCompletedItemsChange}
                defaultChecked={includeCompletedItems}
              >
            Include Completed Items
              </Checkbox>
            </div>
            <Button type="primary" onClick={this.handleRefresh}>Refresh</Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>

          <Table
            dataSource={items}
            loading={fetchInfoCaptureRequestsInProgress}
            onChange={this.fetchInfoCaptureRequestsNext}
            pagination={pagination}
          >
            <Table.Column title='Reference' dataIndex='id' key='id'/>
            <Table.Column title='For' dataIndex='constituent.fullName' key='fullName' render={(text, record) => (record.constituentId ? <Link to={`/constituents/${record.constituentId}/accreditations`}>
              {stripHints(record.constituent.fullName)}
            </Link> : <span>{stripHints(record.constituent.fullName)}</span>)}
            />
            <Table.Column title='Submitted' dataIndex='createdAt' key='createdAt' sorter={true} sortDirections={['ascend', 'descend', 'ascend']} render={createdAt => <Tooltip title={createdAt}><span style={{ cursor: 'default' }}>{moment(createdAt).format('YYYY-MM-DD')}</span></Tooltip>} />
            <Table.Column title='Package' dataIndex='screeningPackage.name' key='screeningPackageName' style={{ wordWrap: 'break-word', wordBreak: 'break-word' }} />
            <Table.Column title="Status" dataIndex='status' key='status' filters={ICR_STATUS_FILTERS} filteredValue={filtersInfo.status} onFilter={(value, record) => record.status === value}
              render={(_, record) => this.renderStatus(record, onboardingSettings)
              }
            />
            <Table.Column title='Checks' dataIndex='checks' key='checks'
              render={record => this.renderICRChecks(record)}
            />
          </Table>
        </Row>
      </div>);
  }
}

export const IcrTrackingPage = connect(
  state => ({
    fetchInfoCaptureRequestsInProgress: state.doc.getIn(['http', 'fetchInfoCaptureRequests', 'inProgress'], true),
    fetchInfoCaptureRequestsSuccess: state.doc.getIn(['http', 'fetchInfoCaptureRequests', 'success'], false),
    fetchInfoCaptureRequestsFailed: state.doc.getIn(['http', 'fetchInfoCaptureRequests', 'failed'], false),
    infoCaptureRequestActionInProgress: state.doc.getIn(['http', 'infoCaptureRequestAction', 'inProgress'], false),
    infoCaptureRequestActionSuccess: state.doc.getIn(['http', 'infoCaptureRequestAction', 'success'], false),
    infoCaptureRequestActionFailed: state.doc.getIn(['http', 'infoCaptureRequestAction', 'failed'], false),
    organizationId: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'id'], null),
    onboardingOrganizationSettings: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'settings', 'screening', 'onboardingOrganization'], null),
    InfoCaptureRequestItems: state.doc.getIn(['http', 'fetchInfoCaptureRequests', 'data', 'items']),
    InfoCaptureRequestTotal: state.doc.getIn(['http', 'fetchInfoCaptureRequests', 'data', 'total'], null),
  }),
  actions,
)(IcrTrackingPageComponent);
